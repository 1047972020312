<!-- 基本信息 -->
<template>
  <div class="box_css01">
    <div class="title_css01">基本信息</div>
    <el-table
      :show-header="false"
      :data="saveData"
      border
      :cell-style="columnStyle"
      :span-method="arraySpanMethod"
      style="width: 100%;"
    >
      <el-table-column width="180" align="center" prop="name1" />
      <el-table-column prop="value1" />
      <el-table-column width="180" align="center" prop="name2" />
      <el-table-column prop="value2" />
      <el-table-column width="180" align="center" prop="name3" />
      <el-table-column
        prop="value3"
      >
        <template slot-scope="scope">
          <img v-if="scope.row.name3 == '照片预览'" :src="scope.row.value3" class="imgStyle">
          <div v-else>{{ scope.row.value3 }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { StudentReportInfo } from "@/api/index.js";
export default {
  props: {
    StudentID: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      saveData: []
    }
  },
  created() {
    this.$nextTick(() => {
      this.getData();
    });
  },
  watch: {
    StudentID: {
      handler(newVal, oldVal) {
        this.getData();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getData() {
      if(!this.StudentID){
        return
      }
      StudentReportInfo({
        StudentID: this.StudentID
      }).then((res) => {
        this.saveData = []
        let user = res.data
        this.pushDetailList('姓名', user.userName, '性别', user.userSex, '照片预览', user.photo)
        this.pushDetailList('出生年月', user.birthday, '身体状况', user.physicalCondition, '照片预览', '')
        this.pushDetailList('民族', user.nation, '联系方式', user.userPhone, '照片预览', '')
        this.pushDetailList('学籍号', user.studentNumber, '身份证号', user.idCardNumber, '照片预览', '')
        this.pushDetailList('自我介绍', user.personalProfile)
      });
    },
    // 用于造数据
    pushDetailList(name1, value1, name2, value2, name3, value3) {
      this.saveData.push({
        name1: name1,
        value1: value1,
        name2: name2,
        value2: value2,
        name3: name3,
        value3: value3
      })
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 4 || rowIndex === 5) {
        if (columnIndex === 1) {
          return [1, 5]
        }
      }
      if (columnIndex === 4 || columnIndex === 5 || columnIndex === 6) {
        if (rowIndex === 0) {
          return {
            rowspan: 4,
            colspan: 1
          }
        } else if (rowIndex < 4) {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    // 自定义列背景色
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 2 || columnIndex === 4 || columnIndex === 6) {
        // 第三第四列的背景色就改变了0和2都是列数的下标
        return 'background:#f3f6fc;'
      } else {
        return 'background:#ffffff;'
      }
    }
  }
}
</script>
<style scoped>
.box_css01 {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  /* margin-bottom: 15px; */
  box-sizing: border-box;
  /* width: 42.75vw; */
  width: 100%;
}
.title_css01{
  font-size: 26px;
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
}
.imgStyle{
  height: 200px;
}
</style>
