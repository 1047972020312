
<!-- 成长趋势 -->
<template>
  <div>
    <div
      id="growthTrend"
      style="height: 565px; width: 100%;margin-top:-35px;"
    />
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'GrowthTrend',
  data() {
    return {
    }
  },
  created() {
    this.$nextTick(() => {
      this.growthTrend()
    })
  },
  methods: {
    growthTrend() {
      var myChart = echarts.init(document.getElementById('growthTrend'))
      // 绘制图表
      myChart.setOption({
        legend: {
          type: 'plain',
          top: 0,
          right: 30,
          itemGap: 64,
          itemWidth: 20,
          itemHeight: 40,
          icon: 'circle',
          selectedMode: false,
          textStyle: {
            fontSize: 16,
            color: '#7c7c7c'
          },
          data: ['思想品德', '学业水平', '身心健康', '艺术素养', '社会实践']
        },
        grid: { left: 0, top: 80, bottom: 20, right: 10, containLabel: true },
        xAxis: {
          type: 'category',
          data: [
            '九月',
            '十月',
            '十一月',
            '十二月',
            '一月',
            '二月',
            '三月',
            '四月',
            '五月',
            '六月',
            '七月'
          ],
          axisLine: { lineStyle: { color: '#ccc' }},
          axisTick: { length: 3 },
          axisLabel: { color: '#999' }
        },
        yAxis: {
          type: 'value',
          axisLine: { show: true, lineStyle: { color: '#ccc' }},
          axisLabel: { color: '#999' },
          splitLine: { show: false }
        },
        tooltip: {
          trigger: 'axis',
          padding: [12, 17, 20, 23],
          textStyle: { color: '#424242' },
          renderMode: 'html',
          className: 'tooltip'
        },
        series: [
          {
            name: '思想品德',
            type: 'line',
            data: [20, 30, 2, 30, 20, 50, 21, 49, 50, 0, 10, 27, 28, 59, 42],
            smooth: true, // 平滑曲线
            showSymbol: false,
            itemStyle: { color: '#126EFC' },
            lineStyle: { width: 3, color: '#126EFC' },
            areaStyle: { color: 'rgba(0, 110, 254, 0.1)' }
          },
          {
            name: '学业水平',
            type: 'line',
            data: [15, 5, 30, 30, 12, 37, 41, 4, 26, 56, 12, 37, 0, 45, 38],
            smooth: true,
            showSymbol: false,
            itemStyle: { color: '#1BB389' },
            lineStyle: { width: 3, color: '#1BB389' },
            areaStyle: { color: 'rgba(27, 179, 137, 0.1)' }
          },
          {
            name: '身心健康',
            type: 'line',
            data: [10, 12, 20, 23, 7, 28, 20, 30, 6, 8, 0, 0, 32, 55, 38],
            smooth: true, // 平滑曲线
            showSymbol: false,
            itemStyle: { color: '#10e5f0' },
            lineStyle: { width: 3, color: '#10e5f0' },
            areaStyle: { color: 'rgba(16, 229, 240, 0.1)' }
          },
          {
            name: '艺术素养',
            type: 'line',
            data: [13, 8, 20, 25, 3, 38, 38, 5, 56, 0, 59, 7, 7, 25, 28],
            smooth: true,
            showSymbol: false,
            itemStyle: { color: '#f0542d' },
            lineStyle: { width: 3, color: '#f0542d' },
            areaStyle: { color: 'rgba(240, 84, 45, 0.1)' }
          },
          {
            name: '社会实践',
            type: 'line',
            data: [20, 5, 10, 0, 25, 30, 5, 20, 45, 20, 35, 0, 5, 10, 25],
            smooth: true,
            showSymbol: false,
            itemStyle: { color: '#f0d22d' },
            lineStyle: { width: 3, color: '#f0d22d' },
            areaStyle: { color: 'rgba(240, 210, 45, 0.1)' }
          }
        ]
      })
    }
  }
}
</script>
<style scoped>

</style>
