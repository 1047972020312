<!-- 提醒铃铛 -->
<template>
  <div class="box_css01">
    <div class="title_css01">提醒铃铛</div>
    <el-table
      :data="studentPractice"
      border
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="90" />
      <el-table-column prop="Title" label="标题" />
      <el-table-column prop="allScore" label="扣分" />
      <el-table-column prop="ClickTime" label="时间">
        <template slot-scope="scope">
          {{ moment(scope.row.ClickTime).format("YYYY-MM-DD") }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    studentPractice: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment: moment
    }
  }
}
</script>
<style scoped>
.box_css01 {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 15px;
  box-sizing: border-box;
  /* width: 42.75vw; */
  width: 100%;
}
.title_css01{
  font-size: 26px;
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
}
</style>
