<template>
  <div class="page">
    <div
      class="projectTop_css issFixed projectTop_css_float"
      v-if="headerFixed"
    >
      <div class="absolute_css">
        <div
          v-for="(item, index) in titleList"
          :key="index"
          :class="{ absolute_item_hover: typeIndex == index }"
          @click="clickTime(index, item.value)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="timeBox">
        <div class="floatLine"></div>
        <div>
          <i style="font-size: 30px;color: #1890ff;" class="el-icon-time"></i>
        </div>
        <div>{{ nowStartTime }} 至 {{ nowEndTime }} 评价报告</div>
        <!-- <div class="floatTitle">报告范围</div> -->
      </div>
      <div class="searchTimeBox">
        <el-date-picker
          size="large"
          ref="pickerRef"
          v-model="nowSearchTime"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          @focus="focusTime"
          :popper-class="'datePicker'"
        >
        </el-date-picker>
        <el-button
          style="margin-left: 5px;"
          size="medium"
          icon="el-icon-search"
          circle
          @click="searchTime()"
          type="primary"
        ></el-button>
      </div>
    </div>
    <div class="top_title_css">
      <div>青岛市崂山区第二实验小学学生素质报告</div>
      <div>({{ moment().format('YYYY') }}年)</div>
    </div>
    <div class="xuexiao_css">
      <div>
        区:
        <div>青岛市崂山区</div>
      </div>
      <div>
        学校:
        <div>崂山区第二实验小学</div>
      </div>
      <div>
        班级:
        <div>{{ className }}</div>
      </div>
      <div>
        姓名:
        <div>{{ userName }}</div>
      </div>
    </div>
    <!-- <basic :StudentID="StudentID" /> -->
    <!-- <component :is="require('./component/basic.vue').default" /> -->
    <div class="projectTop_css" ref="projectTop">
      <div class="absolute_css">
        <div
          v-for="(item, index) in titleList"
          :key="index"
          :class="{ absolute_item_hover: typeIndex == index }"
          @click="clickTime(index, item.value)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="timeBox">
        <div class="floatLine"></div>
        <div>
          <i style="font-size: 30px;color: #1890ff;" class="el-icon-time"></i>
        </div>
        <div>{{ nowStartTime }} 至 {{ nowEndTime }} 评价报告</div>
        <!-- <div class="floatTitle">报告范围</div> -->
      </div>
      <div class="searchTimeBox">
        <el-date-picker
          size="large"
          v-model="nowSearchTime"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <el-button
          style="margin-left: 5px;"
          size="medium"
          icon="el-icon-search"
          circle
          @click="searchTime()"
          type="primary"
        ></el-button>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between">
      <div class="box_css left_jiben">
        <div class="title_css">数据统计</div>
        <basicInformation :objform="ccBABobj" :students="students" />
      </div>

      <!-- 综合素质 -->
      <div class="box_css" style="flex-shrink: 0; margin-left: 15px">
        <div class="title_css">综合素质</div>
        <overallQuality :objform="ccBABobj" />
      </div>
    </div>

    <!-- 成长趋势 -->
    <!-- <div class="box_css">
      <div class="title_css">成长趋势</div>
      <growthTrend />
    </div> -->
    <div class="disl_flex_css">
      <div class="box_css" style="padding: 20px 20px 0;">
        <thought
          :growthTrends="growthTrends"
          :timeIndex="titleList[typeIndex].value"
        />
      </div>
      <div class="box_css" style="padding: 20px 20px 0">
        <academicLevel
          :growthTrends="growthTrends"
          :timeIndex="titleList[typeIndex].value"
        />
      </div>
      <div class="box_css" style="padding: 20px 20px 0">
        <healthy
          :growthTrends="growthTrends"
          :timeIndex="titleList[typeIndex].value"
        />
      </div>
      <div class="box_css" style="padding: 20px 20px 0">
        <artisticAccomplishment
          :growthTrends="growthTrends"
          :timeIndex="titleList[typeIndex].value"
        />
      </div>
      <div class="box_css" style="padding: 20px 20px 0">
        <socialPractice
          :growthTrends="growthTrends"
          :timeIndex="titleList[typeIndex].value"
        />
      </div>
      <div class="box_css" style="padding: 20px 20px 0">
        <reminder
          :growthTrends="rankingWarn"
          :timeIndex="titleList[typeIndex].value"
        />
      </div>
    </div>
    <div>
      <!-- 实践活动 -->
      <morality :studentPractice="studentPractice" />
    </div>

    <div>
      <!-- 学业成就 -->
      <study titleName="学业水平" :studentPractice="ImportMainList1" />
    </div>

    <div>
      <!-- 身体素养 -->
      <askLeave :studentPractice="ImportMainList2" />
    </div>

    <div>
      <!-- 心理健康 -->
      <mentalHealth :studentPractice="ImportMainList3" />
    </div>

    <div>
      <!-- 艺术素养 -->
      <study titleName="艺术素养" :studentPractice="ListByScore4" />
      <!-- <accomplishment :studentPractice="ListByScore4" /> -->
    </div>

    <div>
      <!-- 我的荣誉 -->
      <manufacture :studentPractice="ListByScore5" />
    </div>

    <!-- <div>
      <mindBody :StudentComment="StudentComment" />
    </div> -->

    <!-- 提醒铃铛 -->
    <!-- <div>
      <beLate :studentPractice="rankingWarn.WarnList" />
    </div> -->

    <div>
      <!-- 综合评价 -->
      <synthesize :StudentComment="StudentComment" :userName="userName" />
    </div>
  </div>
</template>
<script>
import {
  StudentReportTotalRanking,
  StudentReportSubitemRanking,
  StudentReportGrowthTrends,
  StudentReportWarn,
  GetStudentPractice,
  GetStudentScoreByTask,
  GetStudentListByScore,
  GetDataImportMainList,
  GetStudentComment,
  StudentReportInfo,
} from '@/api/index.js'
import moment from 'moment'
import basicInformation from './component/basicInformation' // 基本信息
import overallQuality from './component/overallQuality' // 综合素质
import growthTrend from './component/growthTrend' // 成长趋势
import thought from './component/thought'
import academicLevel from './component/academicLevel'
import healthy from './component/healthy'
import artisticAccomplishment from './component/artisticAccomplishment'
import socialPractice from './component/socialPractice'
import reminder from './component/reminder'

import morality from './component/morality'
import study from './component/study'
import askLeave from './component/askLeave'
import accomplishment from './component/accomplishment'
import manufacture from './component/manufacture'
import mindBody from './component/mindBody'
import beLate from './component/beLate'
import synthesize from './component/synthesize'
import mentalHealth from './component/mentalHealth'
import basic from './component/basic'

let ClassID = '047d9329-475e-43b6-8610-75aaaef2769a'
let StudentID = '933d342c-3a34-4589-ae28-27f42bce1761'
let startDateA = ''
let endDateA = ''
export default {
  components: {
    basic,
    basicInformation,
    overallQuality,
    growthTrend,
    thought,
    academicLevel,
    healthy,
    artisticAccomplishment,
    socialPractice,
    reminder,
    morality,
    study,
    askLeave,
    accomplishment,
    manufacture,
    mindBody,
    beLate,
    synthesize,
    mentalHealth,
  },
  data() {
    return {
      moment: moment,
      StudentID: '',
      className: localStorage.getItem('className') || '',
      userName: '',
      headerFixed: false, // 是否吸顶
      ccBABobj: {
        students: [
          { allScore: 0 },
          { allScore: 0 },
          { allScore: 0 },
          { allScore: 0 },
          { allScore: 0 },
        ],
        classinfo: [],
        studentInfo: {
          allScore: 0,
          classSize: 0,
          deductScore: 0,
          ranking: 0,
        },
      }, // 数据统计，综合素质
      growthTrends: {
        students: [],
        classinfo: [],
      }, // 成长趋势
      rankingWarn: {
        students: [],
        classinfo: [],
        WarnList: [],
      },
      studentPractice: {
        infoList: [],
        shsj: 0,
        xxsj: 0,
      },
      ListByScore4: [],
      ListByScore5: [],
      ImportMainList1: [],
      ImportMainList2: [],
      ImportMainList3: [],
      StudentComment: '',
      form: {
        userid: '',
        userName: '',
        photo: '',
      }, // 基本信息
      classinfo: {
        ttID: 0,
        classSize: 0,
        AverageScore: 0,
        MinScore: 0,
        MaxScore: 0,
      }, // 基本得分
      students: {
        allScore: 0,
        deductScore: 0,
        ranking: 0,
      },
      typeIndex: 0,
      titleList: [
        {
          name: '周统计',
          value: 1,
        },
        {
          name: '月统计',
          value: 2,
        },
        {
          name: '学期统计',
          value: 3,
        },
        // {
        //   name: '年统计',
        //   value: 4,
        // },
      ],
      //当前查询的时间端范围
      nowStartTime: '',
      nowEndTime: '',
      //当前查询的日期
      nowSearchTime: '',
      allowup: 0, // 评语是否可修改  0 不可修改
    }
  },
  created() {
    StudentID =
      this.$route.query.StudentID || '933d342c-3a34-4589-ae28-27f42bce1761'
    ClassID = localStorage.getItem('classID') || this.$route.query.ClassID
    this.StudentID = StudentID
    this.getTotalRanking()
    this.clickTime(0, 1)
    StudentReportInfo({
      StudentID: StudentID,
    }).then((res) => {
      this.userName = res.data.userName
    })
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll, true)
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    focusTime() {
      this.$refs.pickerRef.focus()
    },
    //时间查询方法
    searchTime() {
      if (!this.nowSearchTime) {
        this.$message.warning('请选择要查询的日期')
        return
      }
      console.log('moment().week()', moment().week())

      let startDateB = ''
      let endDateB = ''
      //周统计
      if (this.typeIndex == 0) {
        const currentDate = moment(this.nowSearchTime)
        //查询所在的多少周
        const weekNumber = currentDate.isoWeek()
        // 获取最近五周
        let year = currentDate.isoWeekYear()
        let startWeek = weekNumber - 4
        //跨年处理
        if (startWeek < 1) {
          startWeek += 52
          year -= 1
        }
        startDateA = moment(this.nowSearchTime, 'YYYY-MM-DD')
          .isoWeekYear(year)
          .isoWeek(startWeek)
          .weekday(1)
          .format('YYYY-MM-DD')
        endDateA = moment(this.nowSearchTime, 'YYYY-MM-DD')
          .isoWeekYear(currentDate.isoWeekYear())
          .isoWeek(weekNumber)
          .weekday(7)
          .format('YYYY-MM-DD')
        // 获取本周的起始日期（周一）
        startDateB = currentDate
          .clone()
          .startOf('isoWeek')
          .format('YYYY-MM-DD')
        // 获取本周的截至日期（周日）
        endDateB = currentDate
          .clone()
          .endOf('isoWeek')
          .format('YYYY-MM-DD')
        console.log(
          '输出最终的时间',
          startDateA,
          endDateA,
          startDateB,
          endDateB
        )
      } else if (this.typeIndex == 1) {
        //月统计
        const currentDate = moment(this.nowSearchTime)
        //查询当前所在第几个月
        const monthNumber = currentDate.month()
        console.log('打印当前的月份', monthNumber)
        // 获取最近五月
        // startDateA = moment()
        //   .month(monthNumber - 4)
        //   .startOf('month')
        //   .format('YYYY-MM-DD')
        // endDateA = moment()
        //   .month(monthNumber)
        //   .endOf('month')
        //   .format('YYYY-MM-DD')
        startDateA = moment(this.nowSearchTime, 'YYYY-MM-DD')
          .year(currentDate.year())
          .month(monthNumber - 4)
          .startOf('month')
          .format('YYYY-MM-DD')
        endDateA = moment(this.nowSearchTime, 'YYYY-MM-DD')
          .year(currentDate.year())
          .month(monthNumber)
          .endOf('month')
          .format('YYYY-MM-DD')
        // 获取本月的起始日期
        startDateB = currentDate
          .clone()
          .startOf('month')
          .format('YYYY-MM-DD')

        // 获取本月的截至日期
        endDateB = currentDate
          .clone()
          .endOf('month')
          .format('YYYY-MM-DD')
        console.log(
          '打印月的截至日期',
          startDateA,
          endDateA,
          startDateB,
          endDateB
        )
      } else if (this.typeIndex == 2) {
        //学期统计
        let nowMonth = moment(this.nowSearchTime).month() + 1

        if ([2, 3, 4, 5, 6, 7, 8].includes(nowMonth)) {
          startDateB =
            moment(this.nowSearchTime)
              .add(0, 'Y')
              .format('YYYY') + '-02-01'
          endDateB =
            moment(this.nowSearchTime)
              .add(0, 'Y')
              .format('YYYY') + '-07-31'
        } else if ([9, 10, 11, 12].includes(nowMonth)) {
          startDateB =
            moment(this.nowSearchTime)
              .add(0, 'Y')
              .format('YYYY') + '-09-01'
          endDateB =
            moment(this.nowSearchTime)
              .add(+1, 'Y')
              .format('YYYY') + '-01-31'
        } else if (nowMonth == 1) {
          startDateB =
            moment(this.nowSearchTime)
              .add(-1, 'Y')
              .format('YYYY') + '-09-01'
          endDateB =
            moment(this.nowSearchTime)
              .add(0, 'Y')
              .format('YYYY') + '-01-31'
        }

        startDateA = startDateB
        endDateA = endDateB
      }
      this.nowStartTime = startDateB
      this.nowEndTime = endDateB
      this.ConditionalQuery(startDateA, endDateA, startDateB, endDateB)
    },
    handleScroll() {
      // 判断页面滚动的距离是否大于吸顶元素的位置
      this.headerFixed = this.$refs.projectTop.getBoundingClientRect().top < 0
    },
    clickTime(e, val) {
      this.typeIndex = e
      console.log('选择时间', e, val)
      this.nowSearchTime = ''

      let startDateB = ''
      let endDateB = ''
      if (val == 1) {
        // 获取最近五周
        startDateA = moment()
          .week(moment().week() - 5)
          .weekday(1)
          .format('YYYY-MM-DD')
        endDateA = moment()
          .week(moment().week() - 1)
          .weekday(7)
          .format('YYYY-MM-DD')
        // 获取本周
        startDateB = moment()
          .week(moment().week() - 1)
          .weekday(1)
          .format('YYYY-MM-DD')
        endDateB = moment()
          .week(moment().week() - 1)
          .weekday(7)
          .format('YYYY-MM-DD')
      } else if (val == 2) {
        // 获取最近五月
        startDateA = moment()
          .month(moment().month() - 5)
          .startOf('month')
          .format('YYYY-MM-DD')
        endDateA = moment()
          .month(moment().month() - 1)
          .endOf('month')
          .format('YYYY-MM-DD')
        // 获取本月
        startDateB = moment()
          .month(moment().month() - 1)
          .startOf('month')
          .format('YYYY-MM-DD')
        endDateB = moment()
          .month(moment().month() - 1)
          .endOf('month')
          .format('YYYY-MM-DD')
      } else if (val == 3) {
        let nowMonth = moment().month() + 1

        if ([2, 3, 4, 5, 6, 7, 8].includes(nowMonth)) {
          startDateB =
            moment()
              .add(0, 'Y')
              .format('YYYY') + '-02-01'
          endDateB =
            moment()
              .add(0, 'Y')
              .format('YYYY') + '-07-31'
        } else if ([9, 10, 11, 12].includes(nowMonth)) {
          startDateB =
            moment()
              .add(0, 'Y')
              .format('YYYY') + '-09-01'
          endDateB =
            moment()
              .add(+1, 'Y')
              .format('YYYY') + '-01-31'
        } else if (nowMonth == 1) {
          startDateB =
            moment()
              .add(-1, 'Y')
              .format('YYYY') + '-09-01'
          endDateB =
            moment()
              .add(0, 'Y')
              .format('YYYY') + '-01-31'
        }
        startDateA = startDateB
        endDateA = endDateB
        // 获取最近4个季度
        // let qua = moment().quarter()
        // console.log('当前季度', qua)

        // if (qua - 4 < 0) {
        //   let jidu = qua + 1
        //   if (jidu == 1) {
        //     startDateA =
        //       moment()
        //         .add(-1, 'Y')
        //         .format('YYYY') + '-01-01'
        //   } else if (jidu == 2) {
        //     startDateA =
        //       moment()
        //         .add(-1, 'Y')
        //         .format('YYYY') + '-04-01'
        //   } else if (jidu == 3) {
        //     startDateA =
        //       moment()
        //         .add(-1, 'Y')
        //         .format('YYYY') + '-07-01'
        //   } else if (jidu == 4) {
        //     startDateA =
        //       moment()
        //         .add(-1, 'Y')
        //         .format('YYYY') + '-10-01'
        //   }
        // } else {
        //   startDateA = moment().format('YYYY') + '-01-01'
        // }
        // endDateA = moment()
        //   .endOf('quarter')
        //   .format('YYYY-MM-DD')

        // // 获取本季度
        // startDateB = moment()
        //   .startOf('quarter')
        //   .format('YYYY-MM-DD')
        // endDateB = moment()
        //   .endOf('quarter')
        //   .format('YYYY-MM-DD')
      } else if (val == 4) {
        // 获取最近2年
        startDateA =
          moment()
            .add(-1, 'Y')
            .format('YYYY') + '-01-01'
        endDateA = moment().format('YYYY') + '-12-31'

        // 获取本年
        startDateB = moment().format('YYYY') + '-01-01'
        endDateB = moment().format('YYYY') + '-12-31'
      }
      this.nowStartTime = startDateB
      this.nowEndTime = endDateB
      this.ConditionalQuery(startDateA, endDateA, startDateB, endDateB)
    },
    // 条件查询
    ConditionalQuery(startDateA, endDateA, startDateB, endDateB) {
      console.log('打印这些时间', startDateA, endDateA, startDateB, endDateB)

      // 数据统计
      StudentReportSubitemRanking({
        ClassID: ClassID,
        StudentID: StudentID,
        startDate: startDateB,
        endDate: endDateB,
      }).then((res) => {
        console.log('获取到的1111111', res)
        this.ccBABobj.students = res.data.students
        this.ccBABobj.classinfo = res.data.classinfo
      })
      // 报告基本信息 (当前的 近一周)
      StudentReportTotalRanking({
        ClassID: ClassID,
        StudentID: StudentID,
        startDate: startDateB,
        endDate: endDateB,
      }).then((res) => {
        this.ccBABobj.studentInfo = {
          classSize: res.data.classinfo.classSize,
          allScore: res.data.students[0].allScore,
          deductScore: res.data.students[0].deductScore,
          ranking: res.data.students[0].ranking,
        }
      })
      // 成长趋势以及其他图表
      StudentReportGrowthTrends({
        iType: this.titleList[this.typeIndex].value,
        ClassID: ClassID,
        StudentID: StudentID,
        startDate: startDateA,
        endDate: endDateA,
      }).then((res) => {
        console.log('打印获取的趋势', res.data)

        this.growthTrends = res.data
      })
      // 提醒铃铛
      StudentReportWarn({
        iType: this.titleList[this.typeIndex].value,
        ClassID: ClassID,
        StudentID: StudentID,
        startDate: startDateA,
        endDate: endDateA,
      }).then((res) => {
        this.rankingWarn = res.data
      })
      // 获取学生实践活动
      GetStudentPractice({
        StudentID: StudentID,
        startDate: startDateA,
        endDate: endDateA,
        startIndex: 1,
        endIndex: 1000,
      }).then((res) => {
        this.studentPractice = res.data
      })
      // 获取我的荣誉
      GetStudentScoreByTask({
        StudentID: StudentID,
        startDate: startDateA,
        endDate: endDateA,
        hwType: 4,
        startIndex: 1,
        endIndex: 1000,
      }).then((res) => {
        this.ListByScore5 = res.data
      })
      // 艺术素养
      this.getJieKouB(4, 'ListByScore4', startDateA, endDateA)
      // 学业成绩
      this.getJieKouB(1, 'ImportMainList1', startDateA, endDateA)
      // 体育成绩
      this.getJieKouB(2, 'ImportMainList2', startDateA, endDateA)
      // 心理健康
      this.getJieKouB(3, 'ImportMainList3', startDateA, endDateA)
      this.StudentCommentA(startDateB, endDateB)
    },
    StudentCommentA(startDateB, endDateB) {
      // 综合评价
      GetStudentComment({
        iType: this.titleList[this.typeIndex].value,
        ClassID: ClassID,
        StudentID: StudentID,
        startDate: startDateB,
        endDate: endDateB,
      }).then((res) => {
        console.log('获取的综合评语', res)

        this.StudentComment = res.data
        this.allowup = res.data.allowup ? res.data.allowup : 0
      })
    },
    getJieKouA(e, w, startDateA, endDateA) {
      // 查询学生做过的有积分的任务
      GetStudentListByScore({
        StudentID: StudentID,
        startDate: startDateA,
        endDate: endDateA,
        ttParentID: e,
        startIndex: 1,
        endIndex: 1000,
      }).then((res) => {
        this[w] = res.data
      })
    },
    getJieKouB(e, w, startDateA, endDateA) {
      // 查询学生做过的有积分的任务
      GetDataImportMainList({
        StudentID: StudentID,
        startDate: startDateA,
        endDate: endDateA,
        IType: e,
        startIndex: 1,
        endIndex: 1000,
      }).then((res) => {
        this[w] = res.data
      })
    },
    //获取总的
    getTotalRanking() {
      StudentReportTotalRanking({
        ClassID: ClassID,
        StudentID: StudentID,
        startDate: '',
        endDate: '',
      }).then((res) => {
        this.classinfo = res.data.classinfo
        this.students = res.data.students[0]
      })
    },
  },
}
</script>
<style scoped>
.page {
  background-color: #f4f9ff;
  min-height: 100vh;
  padding: 15px;
  width: 100%;
  /* touch-action: none; */
  box-sizing: border-box;
  min-width: 1350px;
}
.box_css {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 30px 0;
  margin-bottom: 15px;
  box-sizing: border-box;
}
.left_jiben {
  flex-grow: 1;
  padding: 30px 30px 25px !important;
  width: 200px;
}
.title_css {
  font-size: 26px;
  font-weight: bold;
  color: #000;
}
.top_title_css {
  text-align: center;
  font-size: 36px;
  padding: 50px 0 30px;
}
.xuexiao_css {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  margin-bottom: 25px;
}

.xuexiao_css > div {
  display: flex;
  align-items: center;
  margin-right: 60px;
}
.xuexiao_css > div:nth-last-child(1) {
  margin-right: 0 !important;
}
.xuexiao_css > div > div {
  /* width: 300px; */
  border-bottom: #000 1px solid;
  flex-shrink: 0;
  margin-left: 20px;
  padding: 0 15px;
}
.disl_flex_css {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.disl_flex_css > div {
  width: 32.7%;
  flex-shrink: 0;
  margin-right: 0.9%;
  padding: 20px 20px 0 !important;
}
.disl_flex_css > div:nth-child(3n) {
  margin-right: 0 !important;
}
.issFixed {
  position: fixed;
  top: 0px;
  left: 15px;
  z-index: 99;
}
.projectTop_css {
  display: flex;
  width: 100%;

  height: 87px;
  align-items: center;
  background-color: #f4f9ff;
  justify-content: space-between;
}
.projectTop_css_float {
  width: calc(100% - 30px) !important;
}
.absolute_css {
  background-color: #fff;
  /* box-shadow: 0 0 13px 0 rgb(82 63 105 / 5%); */
  display: flex;
  padding: 7px;
  border-radius: 10px;
  flex-shrink: 0;
}
.absolute_css > div {
  padding: 10px 15px;
  color: #2c3038;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
  margin-right: 5px;
  flex-shrink: 0;
  transition: all 0.5s;
}
.absolute_css > div:nth-last-child(1) {
  margin-right: 0px;
}
.timeBox {
  /* background-color: #1890ff; */
  background-color: #fff;
  /* box-shadow: 0 0 13px 0 rgb(82 63 105 / 5%); */
  display: flex;
  padding: 7px;
  border-radius: 10px;
  flex-shrink: 0;
  position: relative;
  cursor: unset !important;
}
.timeBox .floatLine {
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: #1890ff; */
  /* background-color: #000; */
  border-radius: 0 0 10px 10px;
  border-bottom: #1890ff 0.05rem solid;
  padding: unset;
}
.timeBox > div {
  padding: 10px 5px;
  /* color: #2c3038; */
  color: #1890ff;
  font-size: 20px;
  /* border-radius: 5px; */
  /* cursor: pointer; */
  cursor: unset !important;
  /* background-color: #1890ff; */
  background-color: #fff;
  /* margin-right: 5px; */
  flex-shrink: 0;
  transition: all 0.5s;
  /* color: #1890ff; */
  /* border-bottom: #1890ff 0.05rem solid; */
  box-sizing: border-box;
}
.searchTimeBox {
  background-color: #fff;
  /* box-shadow: 0 0 13px 0 rgb(82 63 105 / 5%); */
  display: flex;
  padding: 10px 22px;
  border-radius: 10px;
  flex-shrink: 0;
  /* overflow: inherit !important; */
}
.absolute_item_hover {
  background-color: #1890ff !important;
  color: #fff !important;
}
</style>
<style>
/* 自定义日期下拉框位置 */
.datePicker {
  top: 60px !important;
}
</style>
