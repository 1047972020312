<!-- 身体素养 -->
<template>
  <div class="box_css01">
    <div class="title_css01">体质监测</div>
    <!-- <el-table
      :data="tableData"
      border
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="item16" label="检测时间" />
      <el-table-column prop="item01" label="身高(cm)" />
      <el-table-column prop="item02" label="体重(kg)" />
      <el-table-column prop="item03" label="肺活量" />
      <el-table-column prop="item04" label="50米跑(s)" />
      <el-table-column prop="item05" label="坐位体前屈" />
      <el-table-column prop="item06" label="一分钟跳绳" />
      <el-table-column prop="item07" label="一分钟仰卧起坐(3-6年级)" />
      <el-table-column prop="item08" label="50米*8 (5-6年级)" />
      <el-table-column prop="item09" label="胸围" />
      <el-table-column prop="item10" label="体格评价" />
      <el-table-column prop="item11" label="收缩压" />
      <el-table-column prop="item12" label="舒张压" />
      <el-table-column prop="item13" label="脉搏" />
      <el-table-column prop="item14" label="右眼视力" />
      <el-table-column prop="item15" label="右眼视力" />
    </el-table> -->
    <el-table
      :data="studentPractice"
      border
      @row-click="clickTable"
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="90" />
      <!-- <el-table-column prop="allScore" label="评价得分" width="180" /> -->
      <el-table-column prop="Title" label="标题" />
      <!-- <el-table-column prop="allScore" label="评价得分" width="180" /> -->
      <el-table-column prop="iLevel" label="等级" />
      <el-table-column prop="CreateDate" label="时间">
        <template slot-scope="scope">
          {{ moment(scope.row.CreateDate).format('YYYY-MM-DD') }}
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      append-to-body
      width="50%"
    >
      <el-descriptions :column="3" border>
        <el-descriptions-item v-for="(item, index) in dialogList" :key="index">
          <template slot="label">
            {{ item.dTitle }}
          </template>
          {{ item.dContent }}
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment'
import { GetDataImportDetailList } from '@/api/index.js'
export default {
  props: {
    studentPractice: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment: moment,
      title: '',
      dialogVisible: false,
      dialogList: [],
      tableData: [
        {
          item01: '180',
          item02: '53',
          item03: '6000',
          item04: '6',
          item05: '50',
          item06: '80',
          item07: '50',
          item08: '',
          item09: '70',
          item10: '优',
          item11: '10',
          item12: '10',
          item13: '50',
          item14: '150',
          item15: '150',
          item16: '2021-10-15',
        },
        {
          item01: '180',
          item02: '53',
          item03: '6000',
          item04: '6',
          item05: '50',
          item06: '80',
          item07: '50',
          item08: '',
          item09: '70',
          item10: '优',
          item11: '10',
          item12: '10',
          item13: '50',
          item14: '150',
          item15: '150',
          item16: '2021-12-15',
        },
        {
          item01: '180',
          item02: '53',
          item03: '6000',
          item04: '6',
          item05: '50',
          item06: '80',
          item07: '50',
          item08: '',
          item09: '70',
          item10: '优',
          item11: '10',
          item12: '10',
          item13: '50',
          item14: '150',
          item15: '150',
          item16: '2022-02-15',
        },
      ],
    }
  },
  methods: {
    clickTable(e) {
      if (e.filePath) {
        window.open(e.filePath)
      } else {
        this.title = e.Title
        this.dialogList = []
        this.dialogVisible = true
        GetDataImportDetailList({
          dimID: e.dimID,
        }).then((res) => {
          console.log(res)
          this.dialogList = res.data
        })
      }
    },
  },
}
</script>
<style scoped>
.box_css01 {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 15px;
  box-sizing: border-box;
  /* width: 42.75vw; */
  width: 100%;
}
.title_css01 {
  font-size: 26px;
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
}
</style>
