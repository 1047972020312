<!-- 综合评价 -->
<template>
  <div class="box_css01">
    <div class="title_css01">
      <div>综合评价</div>
      <el-button
        type="primary"
        v-if="allowup == 1"
        icon="el-icon-edit"
        @click="dialogClick()"
        >修改</el-button
      >
    </div>
    <div class="content_css01" v-html="StudentComment.Comment"></div>
    <el-dialog
      :title="'修改' + userName + '的综合评语'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      append-to-body
      :show-close="false"
      width="50%"
    >
      <el-input
        type="textarea"
        :rows="10"
        placeholder="请输入综合评价评语"
        v-model="textarea"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { upStudentComment } from '@/api/index.js'
export default {
  props: {
    StudentComment: {
      type: [Number, String, Object],
      default: {
        sid: 0,
        Comment: '',
      },
    },
    userName: {
      type: [Number, String],
      default: '',
    },
    //评语是否可修改 0不可以 1可以
    allowup: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      text:
        '上学期综合评价总结如下：<br /><br />你在这学期展现出了令人赞叹的全面素质，无论是学科成绩还是艺术素养，你都交出了满意的答卷。特别值得一提的是，你整个学期都保持着良好的出勤记录，这体现出了你高度的责任心和自律性，真是非常棒！<br /><br />在艺术素质测评中，你的表现尤为突出。无论是基础指标、学业指标，还是校外学习部分，你都取得了满分的成绩，超越了班级所有同学。这充分展示了你在艺术领域的天赋和努力，也反映了你对艺术学习的热情和投入。<br /><br />同样令人瞩目的是，在期末学业成绩检测中，你的语文、数学、英语和科学四门学科成绩均超越了班级所有同学，总分也名列前茅。这全面体现了你在学科知识上的扎实基础和出色的应用能力。你的优异成绩，既是你个人努力的结果，也是老师悉心教导的回报。<br /><br />当然，成长的过程中总有可以进一步提升的空间。老师希望你在保持现有优势的同时，能够继续挖掘自己在各学科中的潜力，尤其是针对自己的薄弱环节进行有针对性的提升。同时，也期待你在艺术领域能够持续深耕，不断拓宽自己的视野和技艺。<br /><br />总之，这学期你的表现可圈可点，老师为你感到由衷的高兴和骄傲。期待在新的学期里，你能继续保持这种奋发向上的精神状态，不断追求卓越，为自己的成长历程增添更多亮丽的色彩！',
      activeName: 'second',
      dialogVisible: false,
      textarea: '',
      usClassID: JSON.parse(localStorage.getItem('userInformation')).ClassID,
      classID: localStorage.getItem('classID'),
    }
  },
  methods: {
    dialogClick() {
      this.dialogVisible = true
      this.textarea = JSON.parse(
        JSON.stringify(this.StudentComment.Comment)
      ).replace(/<br \/>/g, '\n')
    },
    // 确定
    confirm() {
      if (this.StudentComment.sid <= 0) {
        this.$message.error('请退出后重试')
        return
      }
      let pattern = /</g
      let text = this.textarea.replace(pattern, '')
      if (text.length == 0 || text.length > 2000) {
        this.$message.warning('评语内容应在1-2000字之间')
        return
      }
      upStudentComment({
        sid: this.StudentComment.sid,
        Comment: text,
      }).then((res) => {
        this.$message.success('修改成功')
        this.dialogVisible = false
        this.$parent.StudentCommentA()
      })
    },
  },
}
</script>
<style scoped>
/deep/ .el-button {
  padding: 0.5rem 0.9rem !important;
}
.box_css01 {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 15px;
  box-sizing: border-box;
  /* width: 42.75vw; */
  width: 100%;
}
.title_css01 {
  font-size: 26px;
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content_css01 {
  font-size: 18px;
  color: #555454;
  margin-top: 10px;
  text-indent: 2em;
  line-height: 26px;
}
</style>
