<!-- 实践活动 -->
<template>
  <div class="box_css01">
    <div class="title_css01">实践活动</div>
    <div class="title_fu_css">1.学校实践</div>
    <el-table
      :data="tableData01"
      border
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="90" />
      <el-table-column prop="hwTitle" label="标题" width="300" />
      <el-table-column prop="CreateDate" label="时间" width="180">
        <template slot-scope="scope">
          {{ moment(scope.row.CreateDate).format("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column prop="finishContent" label="活动内容" />
    </el-table>
    <div class="title_fu_css" style="margin-top: 30px">2.社会实践</div>
    <el-table
      :data="tableData02"
      border
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="90" />
      <el-table-column prop="hwTitle" label="标题" width="300" />
      <el-table-column prop="CreateDate" label="时间" width="180">
        <template slot-scope="scope">
          {{ moment(scope.row.CreateDate).format("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column prop="finishContent" label="活动内容" />
    </el-table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props:{
    studentPractice: {
    type: Object,
    default() {
      return {
        infoList: [],
        shsj: 0,
        xxsj: 0,
      };
    },
  },
  },
  data() {
    return {
      moment: moment,
      tableData01: [],
      tableData02: [],
    };
  },
  created() {
    this.currentx();
  },
  watch: {
    studentPractice: {
      handler(newVal, oldVal) {
        this.currentx();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    currentx() {
      let ListA = [];
      let ListB = [];
      this.studentPractice.infoList.map((val) => {
        if (val.iType == 1) {
          ListA.push(val);
        }
        if (val.iType == 2) {
          ListB.push(val);
        }
      });
      this.tableData01 = ListA;
      this.tableData02 = ListB;
    },
  },
};
</script>
<style scoped>
.box_css01 {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 15px;
  box-sizing: border-box;
  /* width: 42.75vw; */
  width: 100%;
}
.title_css01 {
  font-size: 26px;
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
}
.title_fu_css {
  font-size: 20px;
  margin: 5px 0 10px;
}
</style>
