import { render, staticRenderFns } from "./morality.vue?vue&type=template&id=bf013570&scoped=true&"
import script from "./morality.vue?vue&type=script&lang=js&"
export * from "./morality.vue?vue&type=script&lang=js&"
import style0 from "./morality.vue?vue&type=style&index=0&id=bf013570&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf013570",
  null
  
)

export default component.exports