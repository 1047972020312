<!-- 身心健康 -->
<template>
  <div class="box_css01">
    <div class="title_css01">身心健康</div>
    <el-table
      :data="tableData"
      border
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="90" />
      <el-table-column prop="name" label="心理筛查" width="280" />
      <el-table-column prop="term" label="时间" width="200" />
      <el-table-column prop="jieguo" label="筛查结果" width="200" />
      <el-table-column prop="content" label="筛查建议" />
    </el-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [{
        name: '2023级第一次心理筛查',
        jieguo: '合格',
        term: '2023-09-15',
        content: '符合中小学生心理健康标准'
      }, {
        name: '2023级第二次心理筛查',
        jieguo: '不合格',
        term: '2023-12-15',
        content: '家长需密切关注和疏导学生心理'
      }, {
        name: '2023级第三次心理筛查',
        jieguo: '合格',
        term: '2024-02-15',
        content: '符合中小学生心理健康标准'
      }]
    }
  }
}
</script>
<style scoped>
.box_css01 {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 15px;
  box-sizing: border-box;
  /* width: 42.75vw; */
  width: 100%;
}
.title_css01{
  font-size: 26px;
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
}
.shuoming{
    margin-top: 15px;
    color: #747373;
    width: 700px;
}
.shuoming>div{
    margin-top: 10px;
}
</style>
