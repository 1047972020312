<!-- 我的荣誉 -->
<template>
  <div class="box_css01">
    <div class="title_css01">我的荣誉</div>
    <!-- <el-table
      :data="tableData"
      border
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="name" label="证书名称" width="280" />
      <el-table-column prop="term" label="获得时间" width="200" />
      <el-table-column prop="type" label="赛事类型" width="200" />
      <el-table-column width="200" label="查看证书">
        <template>
          <div style="color:#7c48f1;">查看证书</div>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="证书描述" />
    </el-table> -->
    <el-table
      :data="studentPractice"
      border
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="90" />
      <!-- <el-table-column prop="Score" label="评价得分" width="180"/> -->
      <el-table-column prop="hwTitle" label="标题" />
      <!-- <el-table-column prop="allScore" label="得分" /> -->
      <el-table-column prop="CreateDate" label="时间">
        <template slot-scope="scope">
          {{ moment(scope.row.CreateDate).format('YYYY-MM-DD') }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    studentPractice: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment: moment,
      tableData: [
        {
          name: '舞蹈四级',
          term: '2021-10-15',
          type: '国家级',
          content: '舞蹈四级考试通过，特发此证明',
        },
        {
          name: '美术四级',
          term: '2022-02-23',
          type: '国家级',
          content: '美术四级考试通过，特发此证明',
        },
        {
          name: '歌颂比赛一等奖',
          term: '2022-07-10',
          type: '市级',
          content: '参加青岛市青少年歌颂比赛并获得一等奖，特发此证明',
        },
      ],
    }
  },
}
</script>
<style scoped>
.box_css01 {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 15px;
  box-sizing: border-box;
  /* width: 42.75vw; */
  width: 100%;
}
.title_css01 {
  font-size: 26px;
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
}
</style>
