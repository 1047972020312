<!-- 提醒铃铛 -->
<template>
  <div>
    <div id="growthTrend06" style="height: 365px; width: 100%;" />
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'GrowthTrend',
  props: {
    growthTrends: {
      type: Object,
      default() {
        return {
          students: [],
          classinfo: [],
        }
      },
    },
    timeIndex: {
      type: [Number, String],
      default: '1',
    },
  },
  data() {
    return {}
  },
  created() {
    this.$nextTick(() => {
      this.growthTrend06()
    })
  },
  watch: {
    growthTrends: {
      handler(newVal, oldVal) {
        this.growthTrend06()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    growthTrend06() {
      if (this.growthTrends.students.length == 0) {
        return
      }
      let stu = []
      let cla = []
      let categ = []
      this.growthTrends.students.map((val) => {
        stu.push(val.allScore)
        if (this.timeIndex == 1) {
          if (!categ.includes(val.Title + '周')) {
            categ.push(val.Title + '周')
          }
        } else if (this.timeIndex == 2) {
          if (!categ.includes(val.Title + '月')) {
            categ.push(val.Title + '月')
          }
        } else if (this.timeIndex == 3) {
          // if (!categ.includes(val.Title + "季")) {
          //   categ.push(val.Title + "季");
          // }
          if (!categ.includes('本学期')) {
            categ.push('本学期')
          }
        } else if (this.timeIndex == 4) {
          if (!categ.includes(val.Title + '年')) {
            categ.push(val.Title + '年')
          }
        }
      })
      this.growthTrends.classinfo.map((val) => {
        cla.push(val.AverageScore)
      })
      var myChart = echarts.init(document.getElementById('growthTrend06'))
      // 绘制图表
      myChart.setOption({
        legend: {
          type: 'plain',
          top: 0,
          right: 0,
          itemGap: 64,
          itemWidth: 20,
          itemHeight: 40,
          icon: 'circle',
          selectedMode: false,
          textStyle: {
            fontSize: 16,
            color: '#7c7c7c',
          },
          data: ['提醒铃铛', '班级平均'],
        },
        grid: { left: 0, top: 30, bottom: 20, right: 10, containLabel: true },
        xAxis: {
          type: 'category',
          data: categ,
          axisLine: { lineStyle: { color: '#ccc' } },
          axisTick: { length: 3 },
          axisLabel: { color: '#999' },
        },
        yAxis: {
          type: 'value',
          axisLine: { show: true, lineStyle: { color: '#ccc' } },
          axisLabel: { color: '#999' },
          splitLine: { show: false },
        },
        tooltip: {
          trigger: 'axis',
          padding: [12, 17, 20, 23],
          textStyle: { color: '#424242' },
          renderMode: 'html',
          className: 'tooltip',
        },
        series: [
          {
            name: '提醒铃铛',
            type: 'line',
            data: stu,
            smooth: true, // 平滑曲线
            showSymbol: false,
            itemStyle: { color: '#dc1681' },
            lineStyle: { width: 3, color: '#dc1681' },
            areaStyle: { color: 'rgba(220, 22, 129, 0.1)' },
          },
          {
            name: '班级平均',
            type: 'line',
            data: cla,
            smooth: true, // 平滑曲线
            showSymbol: false,
            itemStyle: { color: 'rgba(147, 150, 151, 0.3)' },
            lineStyle: { width: 3, color: 'rgba(147, 150, 151, 0.3)' },
            areaStyle: { color: 'rgba(147, 150, 151, 0.1)' },
          },
        ],
      })
    },
  },
}
</script>
<style scoped></style>
