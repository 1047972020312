<!-- 基本信息 -->
<template>
  <div>
    <div class="top_box_css">
      <div class="top_boxA_css" style="background-color: #cde1f3;">
        <div>
          <template v-if="students.allScore == 0">
            <div>一星章</div>
          </template>
          <template v-else>
            <div>
              {{ rankFn(students.ranking, objform.studentInfo.classSize) }}
            </div>
            <!-- <div>
              {{ students.ranking
              }}<span style="font-size: 16px"
                >/{{ objform.studentInfo.classSize }}</span
              >
            </div>
            <div>总排名</div> -->
          </template>
        </div>
        <div>
          <div>{{ students.allScore }}</div>
          <div>总得分</div>
        </div>
        <div>
          <div>{{ students.deductScore }}</div>
          <div>总扣分</div>
        </div>
      </div>
      <div class="top_boxA_css" style="margin-left:20px">
        <div>
          <template v-if="objform.studentInfo.allScore == 0">
            <div>一星章</div>
          </template>
          <template v-else>
            <div>
              {{
                rankFn(
                  objform.studentInfo.ranking,
                  objform.studentInfo.classSize
                )
              }}
            </div>
            <!-- <div>
              {{ objform.studentInfo.ranking
              }}<span style="font-size: 16px"
                >/{{ objform.studentInfo.classSize }}</span
              >
            </div>
            <div>当前排名</div> -->
          </template>
        </div>
        <div>
          <div>{{ objform.studentInfo.allScore }}</div>
          <div>当前得分</div>
        </div>
        <div>
          <div>{{ objform.studentInfo.deductScore }}</div>
          <div>当前扣分</div>
        </div>
      </div>
    </div>
    <div style="margin-top: 15px">
      <div class="basic_title_css">
        思想品德：{{ objform.students[0].conversionScore }}
        <span style="font-size: 15px;color: #909399;">(换算值)</span>
      </div>
      <el-progress
        :text-inside="true"
        :stroke-width="26"
        :percentage="objform.students[0].conversionScore"
      />
      <div class="basic_title_css">
        学业水平：{{ objform.students[1].conversionScore }}
        <span style="font-size: 15px;color: #909399;">(换算值)</span>
      </div>
      <el-progress
        :text-inside="true"
        :stroke-width="26"
        :percentage="objform.students[1].conversionScore"
        status="success"
      />
      <div class="basic_title_css">
        身心健康：{{ objform.students[2].conversionScore }}
        <span style="font-size: 15px;color: #909399;">(换算值)</span>
      </div>
      <el-progress
        :text-inside="true"
        :stroke-width="26"
        :percentage="objform.students[2].conversionScore"
        status="warning"
      />
      <div class="basic_title_css">
        艺术素养：{{ objform.students[3].conversionScore }}
        <span style="font-size: 15px;color: #909399;">(换算值)</span>
      </div>
      <el-progress
        :text-inside="true"
        :stroke-width="26"
        :percentage="objform.students[3].conversionScore"
        status="exception"
      />
      <div class="basic_title_css">
        社会实践：{{ objform.students[4].conversionScore }}
        <span style="font-size: 15px;color: #909399;">(换算值)</span>
      </div>
      <el-progress
        :text-inside="true"
        :stroke-width="26"
        :percentage="objform.students[4].conversionScore"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    objform: {
      type: Object,
      default() {
        return {
          students: [
            { allScore: 0 },
            { allScore: 0 },
            { allScore: 0 },
            { allScore: 0 },
            { allScore: 0 },
          ],
          studentInfo: {
            allScore: 0,
            classSize: 0,
            deductScore: 0,
            ranking: 0,
          },
        }
      },
    },
    students: {
      type: Object,
      default() {
        return {
          allScore: 0,
          deductScore: 0,
          ranking: 0,
        }
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    getPer(e) {
      console.log('打印this.objform.students', this.objform.students)

      return (this.objform.students[e].allScore / 30).toFixed(2)
    },
    //判断所处星章等级
    rankFn(nowNumber, totalNumber) {
      if (nowNumber < Math.round(totalNumber * 0.2)) {
        return '五星章'
      } else if (nowNumber < Math.round(totalNumber * 0.4)) {
        return '四星章'
      } else if (nowNumber < Math.round(totalNumber * 0.6)) {
        return '三星章'
      } else if (nowNumber < Math.round(totalNumber * 0.8)) {
        return '二星章'
      } else {
        return '一星章'
      }
    },
  },
}
</script>
<style scoped>
.top_box_css {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px;
}
.top_boxA_css {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 20px 40px;
  border-radius: 10px;
  background-color: #dcd5ff;
}
.top_boxA_css > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top_boxA_css > div > div:nth-child(2) {
  font-size: 16px;
  color: #999898;
}
.top_boxA_css > div > div:nth-child(1) {
  font-size: 40px;
  color: #000;
  font-weight: bold;
}
.basic_title_css {
  margin: 25px 0 5px;
  font-size: 20px;
}
</style>
