<!-- 学业成就 -->
<template>
  <div class="box_css01">
    <div class="title_css01">{{ titleName }}</div>
    <!-- <el-table
      :data="tableData"
      border
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%"
    >
      <el-table-column prop="xk" label="科目" />
      <el-table-column prop="gys" label="第一次" />
      <el-table-column prop="gyx" label="第二次" />
      <el-table-column prop="ges" label="第三次" />
      <el-table-column prop="gex" label="第四次" />
      <el-table-column prop="gss" label="第五次" />
      <el-table-column prop="gsx" label="第六次" />
      <el-table-column prop="hg" label="合格性考试成绩" />
    </el-table> -->
    <el-table
      :data="studentPractice"
      @row-click="clickTable"
      border
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="90" />
      <!-- <el-table-column prop="allScore" label="评价得分" width="180" /> -->
      <el-table-column prop="Title" label="标题" />
      <el-table-column prop="iLevel" label="等级" />
      <el-table-column prop="CreateDate" label="时间">
        <template slot-scope="scope">
          {{ moment(scope.row.CreateDate).format('YYYY-MM-DD') }}
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      append-to-body
      width="50%"
    >
      <el-descriptions :column="3" border>
        <el-descriptions-item v-for="(item, index) in dialogList" :key="index">
          <template slot="label">
            {{ item.dTitle }}
          </template>
          {{ item.dContent }}
          <span v-if="item.allScore && item.allScore != 0"
            >({{ Number(item.allScore) }}分)</span
          >
        </el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment'
import { GetDataImportDetailList } from '@/api/index.js'
export default {
  props: {
    studentPractice: {
      type: Array,
      default: () => [],
    },
    titleName: {
      type: String,
      default: '学业水平',
    },
  },
  data() {
    return {
      moment: moment,
      title: '',
      dialogVisible: false,
      dialogList: [],
    }
  },
  methods: {
    clickTable(e) {
      if (e.filePath) {
        window.open(e.filePath)
      } else {
        this.title = e.Title
        this.dialogList = []
        this.dialogVisible = true
        GetDataImportDetailList({
          dimID: e.dimID,
        }).then((res) => {
          console.log(res)
          this.dialogList = res.data
        })
      }
    },
  },
}
</script>
<style scoped>
.box_css01 {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 15px;
  box-sizing: border-box;
  /* width: 42.75vw; */
  width: 100%;
}
.title_css01 {
  font-size: 26px;
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
}
</style>
