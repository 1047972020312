<!-- 综合素质 -->
<template>
  <div>
    <div id="overallQuality" style="height: 565px; width: 565px" />
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'OverallQuality',
  props: {
    objform: {
      type: Object,
      default() {
        return {
          students: [],
          classinfo: [],
        }
      },
    },
  },
  data() {
    return {}
  },
  created() {
    this.$nextTick(() => {
      this.overallQuality()
    })
  },
  watch: {
    objform: {
      handler(newVal, oldVal) {
        this.overallQuality()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    overallQuality() {
      if (
        this.objform.classinfo.length == 0 ||
        this.objform.students.length == 0
      ) {
        return
      }
      let stu = []
      this.objform.students.map((val) => {
        stu.push(val.conversionScore)
      })
      let cla = []
      this.objform.classinfo.map((val) => {
        cla.push(val.conversionScore)
      })

      // let arrNum = [...stu, ...cla]
      // let max = Math.max.apply(null,arrNum) + 10;
      let max = 100
      var myChart = echarts.init(document.getElementById('overallQuality'))
      // 绘制图表
      myChart.setOption({
        backgroundColor: '#fff',
        color: ['#388bf1', '#61BE67'],
        tooltip: {
          show: false, // 弹层数据去掉
        },
        legend: {
          icon: 'roundRect',
          // left: '47%',
          top: '92%',
          show: true,
          padding: [3, 5],
          // right: '50',
          y: '1',
          center: 0,
          itemWidth: 20,
          itemHeight: 20,
          itemGap: 26,
          z: 3,
          // orient: 'horizontal',
          data: ['学生表现', '班级平均'],
          textStyle: {
            fontSize: 16,
            color: '#7c7c7c',
          },
        },
        radar: {
          center: ['50%', '50%'], // 外圆的位置
          radius: '75%',
          name: {
            textStyle: {
              color: '#7c7c7c',
              fontSize: 16,
              fontWeight: 400,
              fontFamily: 'PingFangSC-Regular,PingFang SC',
              fontStyle: 'normal',
            },
          },
          // TODO:
          indicator: [
            {
              name: '思想品德',
              max: max,
            },
            {
              name: '学业水平',
              max: max,
            },
            {
              name: '身心健康',
              max: max,
            },
            {
              name: '艺术素养',
              max: max,
            },
            {
              name: '社会实践',
              max: max,
            },
          ],
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
            areaStyle: {
              // 分隔区域的样式设置。
              color: ['#FAFAFA'], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
            },
          },
          axisLine: {
            // 指向外圈文本的分隔线样式
            lineStyle: {
              color: 'rgba(255,255,255,0.2)',
            },
          },
          splitLine: {
            lineStyle: {
              type: 'solid',
              color: '#ECECEC', // 分隔线颜色
              width: 1, // 分隔线线宽
            },
          },
        },
        series: [
          {
            type: 'radar',
            symbolSize: 5,
            data: [
              {
                // TODO:
                value: stu,
                name: '学生表现',
                areaStyle: {
                  normal: {
                    color: {
                      type: 'radial',
                      x: 0.5,
                      y: 0.5,
                      r: 0.5,
                      colorStops: [
                        {
                          offset: 0,
                          color: 'rgba(46,203,255, 0.14)', // 0% 处的颜色
                        },
                        {
                          offset: 0.15,
                          color: 'rgba(46,203,255, 0.14)', // 100% 处的颜色
                        },
                        {
                          offset: 0.75,
                          color: 'rgba(46,203,255, 0.4)', // 100% 处的颜色
                        },
                        {
                          offset: 1,
                          color: 'rgba(46,203,255, 0.5)', // 100% 处的颜色
                        },
                      ],
                      global: false, // 缺省为 false
                    },
                  },
                },
                itemStyle: {
                  // 折线拐点标志的样式。
                  normal: {
                    // 普通状态时的样式
                    lineStyle: {
                      width: 1,
                    },
                    opacity: 0.3,
                  },
                  emphasis: {
                    // 高亮时的样式
                    lineStyle: {
                      width: 5,
                    },
                    opacity: 0,
                  },
                },
              },
              {
                // TODO:
                value: cla,
                name: '班级平均',
                areaStyle: {
                  normal: {
                    color: {
                      type: 'radial',
                      x: 0.5,
                      y: 0.5,
                      r: 0.5,
                      colorStops: [
                        {
                          offset: 0,
                          color: 'rgba(255, 127,0, 0.14)', // 0% 处的颜色
                        },
                        {
                          offset: 0.15,
                          color: 'rgba(255, 127,0, 0.14)', // 100% 处的颜色
                        },
                        {
                          offset: 0.75,
                          color: 'rgba(2255, 127,0, 0.4)', // 100% 处的颜色
                        },
                        {
                          offset: 1,
                          color: 'rgba(255, 127,0, 0.5)', // 100% 处的颜色
                        },
                      ],
                      global: false, // 缺省为 false
                    },
                  },
                },
                itemStyle: {
                  // 折线拐点标志的样式。
                  normal: {
                    // 普通状态时的样式
                    lineStyle: {
                      width: 1,
                    },
                    opacity: 0.3,
                  },
                  emphasis: {
                    // 高亮时的样式
                    lineStyle: {
                      width: 5,
                    },
                    opacity: 0,
                  },
                },
              },
            ],
          },
        ],
      })
    },
  },
}
</script>
<style scoped></style>
