<!-- 艺术素养 -->
<template>
  <div class="box_css01">
    <div class="title_css01">艺术素养</div>
    <!-- <el-table
      :data="tableData"
      border
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="name" label="活动名称" width="280" />
      <el-table-column prop="term" label="学期" width="200" />
      <el-table-column prop="content" label="活动内容" />
    </el-table> -->
    <el-table
      :data="studentPractice"
      border
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="90" />
      <el-table-column prop="allScore" label="评价得分" width="180" /> 
      <el-table-column prop="hwTitle" label="标题" />
      <!-- <el-table-column prop="allScore" label="得分" /> -->
      <el-table-column prop="CreateDate" label="时间">
        <template slot-scope="scope">
          {{ moment(scope.row.CreateDate).format("YYYY-MM-DD") }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    studentPractice: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moment: moment,
      tableData: [{
        name: '一二九短剧《大献金》',
        term: '2018-2019学年上学期',
        content: '参加“一二九”革命短剧演出活动编剧小组几名同学合作，创作整理献金》剧本，并参演其中一个角色:'
      }, {
        name: '英语配音比赛',
        term: '2018-2019学年上学期',
        content: '参加高一年级英语配音大赛，完成配视频下载、前辑和消除人声等工作并角色配音，获得最住男配音奖项。'
      }, {
        name: '参加英语短剧大赛',
        term: '2019-2020学年上学期',
        content: '2019年12月，参加高18 级英语短剧赛。为班级获得特等奖贡献自己的力'
      }, {
        name: '一二九短剧《大献金》',
        term: '2018-2019学年上学期',
        content: '参加“一二九”革命短剧演出活动编剧小组几名同学合作，创作整理献金》剧本，并参演其中一个角色:'
      }, {
        name: '一二九短剧《大献金》',
        term: '2018-2019学年上学期',
        content: '参加“一二九”革命短剧演出活动编剧小组几名同学合作，创作整理献金》剧本，并参演其中一个角色:'
      }]
    }
  }
}
</script>
<style scoped>
.box_css01 {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 15px;
  box-sizing: border-box;
  /* width: 42.75vw; */
  width: 100%;
}
.title_css01{
  font-size: 26px;
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
}
</style>
